* {
  transition: 0.3s;
  transition: 0.5s !important;
}

@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");
.fancy-font {
  font-family: "Dancing Script", cursive !important;
}

@font-face {
  font-family: "CustomFont"; /* Set the name of the custom font */
  src: url("./assets/Hfont.otf"); /* Specify the path to your font file */
}

.carousel-root {
  height: 100%;
}
.carousel-slider {
  height: 100%;
}
.slider-wrapper {
  height: 100%;
}

.slider {
  height: 100%;
}
