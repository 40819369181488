.custom-editor .draft-editor * {
  all: revert;
}
.DraftEditor-editorContainer * {
  all: revert;
  background-color: transparent;
}

.DraftEditor-editorContainer {
  background-color: transparent;
}

.ck-reset_all * {
  /* all: revert; */
  background-color: transparent;
  background: transparent;
}
.ck * {
  /* all: revert; */
  background-color: transparent !important;
  background: transparent !important;
}
